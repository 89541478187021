.quote-form-container {
    background-color: #333; /* Dark background color */
    color: #fff; /* White text */
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 50px;
    margin-right: 50px;
  font-family: Arial, Helvetica, sans-serif;

  }
  
/* .quote-form-image{
  width: 10px;
  height: 10px;
} */


.form-row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
/* padding: 10px; */
}

  .form-group {
    margin-bottom: 20px;
    padding: 10px;
    /* width: 600px; */
  }
  
  label {
    text-align: left;
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  select[id="size"],
  textarea[id="additionalInformation"],
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    input[type="file"],
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  button[type="submit"] {
    margin-left: 10px;
    float: left;
    background-color: white; /* Purple background */
    color: black;
    padding: 12px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: silver; /* Darker purple on hover */
  }

  @media screen and (max-width: 1047px) {
    
    .quote-form-container{
      display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    }
  }

  @media screen and (max-width: 700px) {
    .quote-form-content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }

    img {
      max-width: 100%;
      height: auto;
    }
    .quote-form-container{
      margin-left: 15px;
      margin-right: 15px;
    }
    .form-row{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    /* padding: 10px; */
    }

    textarea[id="additionalInformation"],
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    input[type="number"],
    input[type="file"],
    select {
      width: 100%;
      /* padding: 10px; */
      border: 1px solid #ccc;
      border-radius: 5px;
      box-sizing: border-box;
    }
  }
