.vec-price-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 50px;
    margin-left: 20vh;
    /* margin-right: 20vh; */
    padding: 20px;
    opacity: 0; /* add this to hide the element initially */
  transition: opacity 0.5s; /* add this to animate the opacity */
}
.vec-price-container.animate {
    opacity: 1; /* show the element when animated */
  }

.vec-price-det{
    text-align: left;
}

/* img {
    width: 40%;
} */

.vec-price-det {
    margin: 100px;
}

.vec-price-bullet{
    display: flex;
    flex-direction: row;
    justify-content:flex-start;
    align-items: start;
    margin-top: 15px;
    transform: translateY(20px); /* add this to move the bullet points down initially */
  transition: transform 0.9s; /* add this to animate the transform */
}
.vec-price-bullet.animate {
    transform: translateY(0); /* move the bullet points back up when animated */
  }
.vec-price-bullet svg {
    margin-right: 15px;
  }

  @media screen and (max-width: 1047px) {
    
    .vec-price-container {
        /* display: flex; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px;
        padding: 10px;
        opacity: 0; /* add this to hide the element initially */
  transition: opacity 0.5s; /* add this to animate the opacity */
    }
    .vec-price-container {
      opacity: 0;
      transition: opacity 0.5s;
    }
   .vec-price-container.animate {
      opacity: 1;
    }
    .vec-price-container.animate {
      opacity: 1; /* show the element when animated */
    }
    .vec-price-det {
        margin: 10px;
    }
    .vec-price-bullet.animate {
      transform: translateY(0); /* move the bullet points back up when animated */
    }
  }
  @media screen and (max-width: 480px) {
    .vec-price-container {
        /* display: flex; */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 10px;
        padding: 5px;
        opacity: 0; /* add this to hide the element initially */
  transition: opacity 0.5s; /* add this to animate the opacity */
    }
    .vec-price-container {
      opacity: 0;
      transition: opacity 0.5s;
    }
   .vec-price-container.animate {
      opacity: 1;
    }
    .vec-price-container.animate {
      opacity: 1; /* show the element when animated */
    }
    .vec-price-det {
        margin: 5px;
    }
    .vec-price-bullet.animate {
      transform: translateY(0); /* move the bullet points back up when animated */
    }
  }