.contact-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 50px;
}

.contact-address-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.contact-address-container label {
    margin-top: 10px;
}

.contact-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 50px;
    height: 50%;
  background-color: grey;
  padding: 20px;
  border-radius: 10px;
  }
  
  input,
  textarea {
    width: 300px;
    height: 40px;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  input:focus,
  textarea:focus {
    outline: none;
    box-shadow: 0 0 5px 1px silver;
  }
  
  button {
    width: 200px;
    height: 40px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: silver;
  }

@media screen and (max-width: 890px) {
  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    margin-left: 5px;
    margin-right: 5px;
}

.contact-address-container {
  display: flex;
  margin-left: 10px;
  /* margin-left: 5px; */
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
  
.contact-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  float: right;
  /* margin-left: 5px;
  margin-right: 5px; */
  margin-top: 50px;
  height: 100%;
  /* width: 100%; */
background-color: grey;
/* padding: 20px; */
border-radius: 10px;
}
}