.Home-Cover{
    /* background-color: aqua; */
    /* background-image: linear-gradient(to right, black, grey); */
    height: 399px;
    /* opacity: 0.8; */
    color: #cd7a00;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 10vh;
    font-weight: 600;
    font-family:Arial, sans-serif;
    /* padding: 20%; */
}

/* for smaller screens, set the height to be 100vh (full screen height) */
@media screen and (max-width: 768px) {
    .Home-Cover {
       height: 20vh;
     }
   }
   
   /* for even smaller screens, set the height to be 50vh (half screen height) */
   @media screen and (max-width: 480px) {
    .Home-Cover {
       height: 10vh;
     }
   }

/* .Service-Cover hi {
    font-size: xx-large;
} */
/* style={{ backgroundImage: `url('../../assets/ServicesCover/Cover1.png')`, backgroundSize: 'cover', backgroundPosition: 'center' }} */