/* Basic Styling */
body {
    font-family: Arial, sans-serif; /* Choose your preferred font */
    background-color: #121212; /* Dark background */
    color: white;
    /* background-image: linear-gradient(to left bottom, black, #808080);  */
  }
  
  .SP-app-container {
    font-family: 'Arial', sans-serif;
    background-color: #121212; /* Dark background */
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    /* min-height: 100vh; */
    padding: 1rem;
  }

  .SP-imgs-container {
    font-family: 'Arial', sans-serif;
    background-color: #121212; /* Dark background */
    color: white;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    /* min-height: 100vh; */
    padding: 1rem;
    padding-left: 10vh;
    padding-right: 10vh;
  }
  
  .SP-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); 
    gap: 2rem;
  }
  
  .SP-card {
    background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent white card */
    border-radius: 10px;
    padding: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow */
    text-align: left; /* Align text to the left */
    transition: transform 0.2s ease; /* Smooth transition for hover effect */
  }

  .SP-card {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s, transform 0.5s;
  }
  
  .SP-card.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  .SP-card:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      transform: scale(1.15)
  }
  
  .SP-card-content {
    padding: 0.5rem;
  }
  
  .SP-card-title {
    color: silver; /* Yellow heading color */
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  .SP-card-description {
    line-height: 1.6; 
  }

  /* Add the following styles to your Card.css */
  .SP-card-grid-image {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
.SP-card-image {
    width: 100%;
    border-radius: 10px;
    border: 1px solid yellow;
  }

  .SP-card-img {
    background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent white card */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow */
    text-align: left; /* Align text to the left */
    transition: transform 0.2s ease; /* Smooth transition for hover effect */

  }

  .SP-card-img {   
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s, transform 0.5s;
  background-image: linear-gradient(to bottom right, orange, orange, skyblue, skyblue);

  }
  
  .SP-card-img.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  .SP-card-img:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      transform: scale(1.15);
    background-image: linear-gradient(to bottom right, skyblue, skyblue, orange, orange );
  }

  .SP-card-img-content {
    padding: 0.5rem;
    border: 10px;
    border-color: aliceblue;
  }