.price-list {
    margin: 40px auto; /* add this line */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border: 5px solid grey;
    border-radius: 20px;
    width: 500px;
    /* background-image: linear-gradient(to right bottom, #1f0e31, #03043f, #050453); */
    transition: all 0.3s ease-in-out;
  }
  
  .price-list:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
  }
  
  .category-selector,
  .quantity-selector {
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  select {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .price {
    font-size: 1.5em;
    transition: all 0.3s ease-in-out;
  }
  
  .price:hover {
    color: silver;
  }
  
  /* Responsive styles */
  
  @media (max-width: 768px) {
   .price-list {
      width: 300px;
    }
  }
  
  @media (max-width: 480px) {
   .price-list {
      width: 250px;
    }
  }