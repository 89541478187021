.Service-Cover{
    /* background-color: aqua; */
    /* background-image: linear-gradient(to right, black, grey); */
    height: 60vh;
    /* opacity: 0.6; */
    /* color: rgba(255, 255, 255, 0); */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    font-size: 10vh;
    font-weight: 600;
    font-family:Arial, sans-serif;
    /* padding: 20vh; */

}
.Service-Cover h3{
    color: black;
}

.services-routes-links{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Service-Cover  svg{
    margin-right: 20px;
    margin-left: 20px;
}
.Service-Cover a {
    color: white;
    text-decoration: none;
    font-size:5vh;
}

@media screen and (max-width: 768px) {
    .Service-Cover {
       height: 25vh;
       font-size: 5vh;
     }
     .Service-Cover a {
        font-size:2vh;
    }
     .services-routes-links{
        size: 5vh;
    }
   }
   
   /* for even smaller screens, set the height to be 50vh (half screen height) */
   @media screen and (max-width: 480px) {
    .Service-Cover {
       height: 10vh;
       font-size: 2vh;
     }
     .Service-Cover a {
        font-size:1vh;
    }
     .services-routes-links{
        size: 2vh;
    }
   }


/* .Service-Cover hi {
    font-size: xx-large;
} */
/* style={{ backgroundImage: `url('../../assets/ServicesCover/Cover1.png')`, backgroundSize: 'cover', backgroundPosition: 'center' }} */