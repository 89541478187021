.logo-carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 95%;
    margin: 40px auto;
    padding: 20px;
    background-color: grey;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .logo-carousel h2 {
    margin-top: 0;
    font-weight: bold;
    color: #333;
  }

  .logo-carousel-window {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .logo-window {
    display: flex;
    justify-content: center;
    align-items: center;
    /* overflow: hidden; */
    width: 100%; /* Adjust this as needed */
  }
  
  .logo-window img {
    max-width: 20%; /* Adjust this based on displayCount */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the logo fits within the element's box */
    /* margin-right: 10px; Adjust spacing between logos */
    /* margin-left: 10px; */
  }
  
  
  /* Navigation buttons */
  .nav-button {
    /* margin-right: 50px;
    margin-left: 50px; */
    width: 50px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 24px; /* Adjust icon size */
  }

  .nav-button:hover{
    background-color: transparent;
  }
  
  /* Medium screens (show 2 logos) */
@media (max-width: 1024px) {
    .logo-window img {
      max-width: 40%; /* Show 2 logos */
      margin-right: 5px;
    }

    .logo-window {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    
  }
  
  /* Small screens (show 1 logo) */
  @media (max-width: 768px) {
    .logo-window img {
      max-width: 80%; /* Show 1 logo */
      margin-right: 0; /* No spacing needed for a single logo */
    }
    .logo-window {
        display: flex;
        justify-content: center;
        align-items: center;
      }
  }
  