/* Global Styles */

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  /* body {
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
  } */
  
  /* Payment Methods Styles */
  
  .payment-methods {
    max-width: 500px;
    margin: 40px auto;
    padding: 20px;
    background-color: grey;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .payment-methods h2 {
    margin-top: 0;
    font-weight: bold;
    color: #333;
  }
  
  .payment-method {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    /* border-bottom: 1px solid #ccc; */
  }
  
  .payment-method:last-child {
    border-bottom: none;
  }
  
  /* .payment-method img { */
    /* width: 40px; */
    /* height: 300px; */
    /* margin-right: 10px; */
  /* } */
  
  /* Animation */
  
  .payment-method {
    transform: scale(0.9);
    transition: transform 0.3s ease-in-out;
  }
  
  .payment-method:hover {
    transform: scale(1);
  }
  
 /* Responsive Styles */

@media (max-width: 768px) {
  .payment-methods {
    max-width: 65vw; /* Adjusted to be relative to the viewport width */
    margin: 10px auto;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .payment-methods {
    max-width: 50vw; /* Adjusted to be relative to the viewport width */
    /* margin: 5px auto; */
    padding: 5px;
  }
  
}
