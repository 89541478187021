/* Basic Styling */
body {
    font-family: Arial, sans-serif; /* Choose your preferred font */
    background-color: #121212; /* Dark background */
    color: white; 
  }
  
  .container {
    display: flex;                /* Position text and description */
    justify-content: space-between; 
    align-items: center;
    padding: 20vh;                /* Add padding to the container */
  }
  
  /* Text Styling */
  .text-content {
    text-align: left; 
  }
  
  .title {
    font-size: 1.2em;
    color: #aaa;                  /* Light gray for "What We Provide" */
    margin-bottom: 5px;
  }
  
  .heading {
    font-size: 4em;              /* Large, bold heading */
    font-weight: bold; 
  }
  
  /* Description Styling */
  .description {
    text-align: justify;
    width: 60%;                /* Adjust as needed */
    line-height: 1.6;             /* Improve readability */
  }
  
  /* Starry Background Effect (Optional) */
  .container {
    position: relative; /* Needed for positioning the stars */
  }
  
  .container::before { 
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-image: url("../../assets/backgrounds/1.jpg"); Replace with your image */
    background-size: cover;
    opacity: 0.2;               /* Adjust opacity for desired effect */
    z-index: -1;                /* Place behind the content */
  } 


  .digi-app-container {
    font-family: 'Arial', sans-serif;
    background-color: #121212; /*Dark background*/
    color: white;
/* background-image: linear-gradient(to left bottom, black, black, grey); */
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
  }
  
  .digi-card-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
    gap: 2rem;
  }
  
  .digi-card {
    background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent white card */
    border-radius: 10px;
    padding: 1.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow */
    text-align: left; /* Align text to the left */
    transition: transform 0.2s ease; /* Smooth transition for hover effect */

  }

  .digi-card {   
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s, transform 0.5s;

  }
  
  .digi-card.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  .digi-card:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      transform: scale(1.15);
  }
  
  .digi-card-content {
    padding: 0.5rem;
  }
  
  .digi-card-title {
    color: silver; /* Yellow heading color */
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  .digi-card-description {
    line-height: 1.6; 
  }

  /* Add the following styles to your Card.css */
  .digi-card-grid-image {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid */
    gap: 2rem;
  }
.digi-card-image {
    width: 100%;
    border-radius: 10px;
    border: 2px solid yellow; /* Add a 2px solid border with a color of #ccc */
    
  }

  .digi-card-img {
    background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent white card */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Subtle shadow */
    text-align: left; /* Align text to the left */
    transition: transform 0.2s ease; /* Smooth transition for hover effect */

  }

  .digi-card-img {   
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s, transform 0.5s;
  background-image: linear-gradient(to bottom right, orange, orange, skyblue, skyblue);

  }
  
  .digi-card-img.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  .digi-card-img:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      transform: scale(1.15);
    background-image: linear-gradient(to bottom right, skyblue, skyblue, orange, orange );
  }

  .digi-card-img-content {
    padding: 0.5rem;
    border: 10px;
    border-color: aliceblue;
  }