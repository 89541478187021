.App {
  text-align: center;
  background-image: linear-gradient(to bottom, black,silver grey, silver);
  /* padding-bottom: 5vh;
  width: 100%; */
}

.App-logo {
  /* height: 20vmin; */
  height: 122px;
  pointer-events: none;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.App-button {
  display: inline-block;
  padding: 0.5em 1em;
  margin: 0 0.5em;
  border-radius: 0.25em;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  font-size: 1em;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out;
}


.nav-ul {
  display: flex;
  flex-direction: row;
}

aside#block-13 {
  background-color: grey;
  padding-left: 5px;
  padding-right: 5px;
}


.selected-route {
  text-decoration: underline;
  color: white;
}

@media screen and (max-width: 768px) {

}