.App-header {
  /* background-color: #282c34; */
  /* background-image: linear-gradient(to right, #cd7a00 , #cd7a00, #ffd53b); */
  background-image: linear-gradient(to right, black, black,  grey);
  min-height: 20vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 15vh;
  padding-right: 15vh;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  font-weight: bold;
  z-index: 100;
}

.App-header li {
  list-style: none;
  padding: 10px;
}

.App-header a {
  text-decoration: none;
  color: white;
  transition: all 0.3s ease 0s;
}

.nav-link {
  text-decoration: none;
  color: black;
}

.nav-link:hover {
  text-decoration: underline;
  color: #ccc;
}

#home-link .nav-link,
.nav-link.selected {
  text-decoration: underline;
  color: #ccc;
}

.menu-item {
  /* margin-right: 40px; */
  display: flex;
  flex-direction: row;
}

.menu-item:hover .sub-menu {
  display: block;
}

.menu-item:hover {
  cursor: pointer;
}

.menu-item:hover {
  text-decoration: underline;
  color: #ccc;
}

.sub-menu {
  display: none;
  position: absolute;
  top: 100%;
  font-size: large;
  left: 0;
  width: 120%;
  background-image: linear-gradient(to bottom, black, grey);
  border: 1px solid #ccc;
  padding-left: 0;
  margin-left: 0;
  z-index: 101;
}


@media screen and (max-width: 768px) {
  .App-header {
    flex-direction: row;
    align-items: flex-start;
    padding-left: 2vh;
    padding-right: 2vh;
  }


  .menu-item {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .sub-menu {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background-image: none;
    border: none;
  }

  .menu-button {
    display: block;
    background-color: black;
    color: white;
    padding: 10px;
    border: none;
    width: 10%;
    margin-top: 40px;
    margin-right: 40px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 1.5rem;

  }

  .menu-button:focus {
    outline: none;
  }

  .menu-hidden {
    display: none;
  }
}

.menu-button {
  display: none;
}

.menu-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */
  width: 100%;
  /* background-color: #f9f9f9; */
  /* background-color: #333; */
  background-color: #121212;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  font-size: calc(10px + 2vmin);
  color: white;
}

.menu-items li {
  color: #121212;
  margin-bottom: 10px;
}

.menu-items a {
  text-decoration: none;
  /* color: #333; */
  color: white;
}

.menu-items a:hover {
  text-decoration: underline;
  color: silver;
}

/* Add this media query */
@media only screen and (max-width: 768px) {
  .menu-button {
    display: block;
    width: 55px;
    /* padding: 2vh; */
    
  }
  .nav-ul {
    display: none;
  }
}

/* Update the existing media query */
@media only screen and (min-width: 769px) {
  .menu-button {
    display: none;
  }
  .menu-items {
    display: none;
  }
  .nav-ul {
    display: flex;
  }
}