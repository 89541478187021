.home-page {
    position: relative;
    height: 100vh; /* adjust the height to your liking */
    width: 100%;
    overflow: hidden;
  }
  
  .home-page video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; /* adjust the text color to your liking */
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* add a text shadow to improve readability */
  }
  
  .overlay > * {
    z-index: 1; /* ensure the overlay content is on top of the video */
  }


  /* satisfation part css */
  .overlay2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 170%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; /* adjust the text color to your liking */
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* add a text shadow to improve readability */
  }
  
  .overlay2 > * {
    z-index: 1; /* ensure the overlay content is on top of the video */
  }

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: transparent;
    padding: 2rem;
    color: white;
  }
  
  .card {
    text-align: center;
    border-right: 1px solid transparent; /* add border-right to each card */
    padding: 1rem;
    /* margin-left: 10vh; */
    padding-right: 10vh;
    width: 30%; /* adjust width to fit your needs */
  }
  
  .card:not(:last-child) { /* remove border-right from the last card */
    border-right: 1px solid white;
    /* margin-right: 10vh; */
  }
  
  .number {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }
  
  .label {
    font-size: 1rem;
    text-align: center;
  }


  @media screen and (max-width: 780px) {
    .container {
      flex-direction: column;
    }

    .card {
      text-align: center;
      border-right: 0px solid transparent;
      border-bottom: 1px solid transparent; /* add border-right to each card */
      padding-bottom: 10vh;
      padding-left: 6vh;
      width: 400px;
      /* width: 30%; adjust width to fit your needs */
    }
    
    .card:not(:last-child) { /* remove border-right from the last card */
      border-bottom: 1px solid white;
      border-right: 0px solid transparent;
      /* margin-right: 10vh; */
    }
  }