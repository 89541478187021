/* Basic Styling */
body {
    font-family: Arial, sans-serif; /* Choose your preferred font */
    background-color: #121212; /* Dark background */
    color: white; 
  }
  /* animations */
  .page-container {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .page-container.show {
    opacity: 1;
  }
  .page-title {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
  
  .page-title.show {
    font-family:cursive ;
    opacity: 1;
    transform: translateY(0);
  }
  
  .page-heading {

    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
  
  .page-heading.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  .page-description {
    opacity: 0;
    transform: translateX(20px);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  }
  .page-description.show {
    opacity: 1;
    transform: translateX(0);
  }

  .image-container {
    margin: 10px;
    transform-style: preserve-3d;
    perspective: 1000px;
    animation: comeFromDepth 20s ease-in-out;
  }
  
  @keyframes comeFromDepth {
    0% {
      transform: translateZ(-500px);
    }
    100% {
      transform: translateZ(0);
    }
  }

  .page-container {
    /* margin-top: 90px; */
    /* background-image: linear-gradient(to bottom, black, black); */
    display: flex;                /* Position text and description */
    justify-content: space-between; 
    align-items: center;
    padding: 5vh;                /* Add padding to the container */
    padding-left: 15vh;
    padding-right: 15vh;
  }
  
  /* Text Styling */
  .page-text-content {
    text-align: left; 
    /* margin: 20px;  */
    margin-left: 10vh;
    margin-right: 10vh;
  }
  
  .page-title {
    font-size: 1.2em;
    color: #aaa;                  /* Light gray for "What We Provide" */
    margin-bottom: 5px;
  }
  
  .page-heading {
    font-size: 4em;              /* Large, bold heading */
    font-weight: bold; 
    /* white-space: nowrap;          Prevent text from wrapping */
  }
  
  /* Description Styling */
  .page-description {
    margin-left: 10vh;
    margin-right: 10vh;
    text-align: justify;
    width: 60%;              /*  Adjust as needed */
    line-height: 1.6;             /* Improve readability */
  }
  
  /* Starry Background Effect (Optional) */
  .page-container {
    position: relative; /* Needed for positioning the stars */
  }
  
  .page-container::before { 
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-image: url("../../assets/backgrounds/1.jpg"); Replace with your image */
    background-size: cover;
    opacity: 0.2;               /* Adjust opacity for desired effect */
    z-index: -1;                /* Place behind the content */
  } 

  @media screen and (max-width: 1090px) {

    .page-container {
    display: flex;                /* Position text and description */
    justify-content: space-between; 
    flex-direction: column;
    align-items: center;
    /* width: 100%; */
    padding: 5vh;         
  }

  /* Description Styling */
  .page-description {
    text-align: justify;
    width: 100%;                /* Adjust as needed */
    line-height: 1.6;             /* Improve readability */
  }
  
  .page-heading {
    font-size: 4em;              /* Large, bold heading */
    font-weight: bold; 
  }

  }
  @media screen and (max-width: 480) {
    .page-container{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* width: 100%; */
      padding: 10px;
    }
    
    .page-heading {
      font-size: 2em;              /* Large, bold heading */
      font-weight: bold; 
    }
  }