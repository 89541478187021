.footer {
  background-color: #222;
  /* padding: 2rem 0; */
  margin-left: 50px;
  margin-right: 50px;
  padding: 25px;
  /* background-image: linear-gradient(to bottom, #cd7a00, #cd7a00); */
  /* background-image: linear-gradient(to bottom, #1f0e31, #03043f, #050453); */
  /* background-image: linear-gradient(to bottom, grey, grey, grey, silver); */
  border-radius: 10px;
  margin-bottom: 20px;
}

.footer-animate {
  transform: translateY(100%);
  transition: transform 0.5s ease-in-out;
  transition-delay: 0.4s;
}

.footer-animate.show {
  transform: translateY(0);
}

.footer-grid {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: Arial, Helvetica, sans-serif;
  opacity: 0;
  transition: opacity 0.6s ease-in-out;
  transition-delay: 0.4s;

}

/* .column1,
.column2,
.column3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
} */

.column1,
.column2,
.column3 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%; /* added to ensure full width */
  opacity: 0; /* added to initially hide the elements */

}
.column1-animate,
.column2-animate,
.column3-animate {
  transform: translateY(100%);
  transition: transform 0.7s ease-in-out, opacity 0.5s ease-in-out; /* added opacity transition */
  transition-delay: 0.4s;
}

.column1-animate.show,
.column2-animate.show,
.column3-animate.show {
  transform: translateX(0);
  opacity: 1; /* show the elements */
}
.column1 {
  width: 15%;
  transition: transform 0.8s ease-in-out;
  transition-delay: 0.4s;

}

.column2 {
  width: 55%;
  transition: transform 0.8s ease-in-out;
  transition-delay: 0.4s;

}

.column3 {
  width: 30%;
  transition: transform 0.8s ease-in-out;
  transition-delay: 0.4s;

}

.footer-grid h3 {
  color: #fff;
  text-align: left;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  margin-top: 1rem;

}

.footer-grid h1 {
  color: white;
  text-align: left;
  margin-bottom: 1rem;
  margin-top: 1rem;

}

.footer-grid p {
  text-align: left;
  margin-top: 1rem;


}

.footer-grid ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.social-media-lu {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.social-media-lu li {
  margin-bottom: 0.5rem;
}

.footer-grid li {
  margin-bottom: 0.5rem;
}

.footer-grid a {
  color: #fff;
  text-decoration: none;
}

.footer-grid form {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.footer-grid form input[type="email"] {
  border: none;
  background-color: #fff;
  padding: 1rem;
  color: #fff;
  width: 150%;
  border-radius: 10px;
  font-size: large;
}

.footer-grid form button[type="submit"] {
  border: none;
  background-color: white;
  padding: 0.5rem;
  color: #222;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
  font-size: larger;
}

.footer-grid ul li a {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  justify-content: flex-start;
}

.footer-grid ul li a img {
  width: 2rem;
  height: 2rem;
  margin-right: 0.5rem;
}

.footer-grid.show {
  opacity: 1;
}
@media screen and (max-width: 890px) {
  .footer {
    margin-left: 15px;
    margin-right: 15px;
  }

  .footer-grid {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: Arial, Helvetica, sans-serif;
  }

  .column1,
  .column2,
  .column3 {
    width: 100%;
  }

  .footer-grid form input[type="email"] {
    width: 200%;
  }

  .footer-grid form button[type="submit"] {
    width: 120%;
  }
}

@media screen and (max-width: 480px) {
  .footer {
    margin-left: 15px;
    margin-right: 15px;
  }

  .footer-grid h3 {
    font-size: 1.1rem;
  }

  .footer-grid h1 {
    font-size: 0.9rem;
  }

  .footer-grid form input[type="email"] {
    font-size: medium;
  }

  .footer-grid form button[type="submit"] {
    font-size: medium;
  }
}

.copyright {
  margin-top: 20px;
}

.copyright-Link{
  text-decoration: none;
  color: white;
  margin-left: 10px;
  margin-right: 10px;
}

.copyright-selected-route {
  text-decoration: underline;
  color: grey;
  margin-left: 10px;
  margin-right: 10px;
}
